<template>
  <section class="sticky top-0 bg-white z-50 shadow-lg">
    <div class="scroll-text hidden lg:block">
      Precautions to be taken by the students before enrolling in programmes offered under Open & Distance Learning (ODL) and/or Online Learning mode -
          <a
            href="https://api.onlineyenepoya.com/media/brochure/UGC_20240319152222_1.pdf"
            target="blank"
            class="text-[#d02829]"
          >
           Link
          </a>
        </div>
    <div class="w-full mx-auto max-w-7xl flex  justify-between">
 
      <nav class="container px-3 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div class="relative flex items-center justify-between mx-auto">
          <div class="w-[1/2] flex items-center gap-5">
            <a href="/">
              <img src="../assets/logo.webp" class="sm:hidden hidden w-[250px] lg:block" alt />
              <img src="../assets/logo.webp" class="w-[10rem] sm:block lg:hidden" alt />
            </a>

            <div
              class="flex gap-2 items-center bg-[#81C8DC] lg:px-2 p-1 sm:text-sm rounded-2xl sm:p-2"
            >
              <button
                id="mega-menu-icons-dropdown-button"
                data-dropdown-toggle="mega-menu-icons-dropdown"
                class="flex items-center justify-between px-2 lg:text-lg text-[14px] font-semibold text-white bg-[#81C8DC] rounded-xl"
              >
                Programs
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="mega-menu-icons-dropdown"
                class="navi !transform-none absolute lg:!top-[90px] !top-[60px] !left-0 (edited) hidden flex flex-col lg:flex-row  w-full z-10 mx-auto max-w-7xl bg-[#97989A] rounded-b-xl drop-shadow-2xl"
              >
                <div class="lg:w-[200px] w-full bg-[#94C34D]">
                  <div class="p-4 font-semibold bg-[#81C8DC] text-white flex gap-2 lg:text-left text-center md:text-center justify-center lg:justify-start  items-center">
                    <p>Bachelors</p>
                    <img src="../assets/chevron_right_icon.webp" alt />
                  </div>
                

              <ul class="flex justify-evenly lg:gap-1 gap-3 mb-0 list-none lg:flex-col  flex-row">
                <li class="mb-px w-full mr-2 last:mr-0 lg:flex-col lg:text-left text-center">
                  <a class="text-[15px] font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block" v-on:click="toggleTabs(1)" v-bind:class="{'text-white bg-gray-500': openTab !== 1, 'text-white bg-[#94C34D]': openTab === 1}">
                    BCOM
                  </a>
                </li>
                <li class="mb-px w-full mr-2 last:mr-0 lg:flex-col lg:text-left text-center">
                  <a class="text-[15px] font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block " v-on:click="toggleTabs(2)" v-bind:class="{'text-white bg-gray-500': openTab !== 2, 'text-white bg-[#94C34D]': openTab === 2}">
                    BBA
                  </a>
                </li>
                
                <li class="mb-px w-full mr-2 last:mr-0 lg:flex-col lg:text-left text-center">
                  <a class="text-[15px] font-bold uppercase px-5 py-3 sm:shadow-lg sm:rounded block " v-on:click="toggleTabs(3)" v-bind:class="{'text-white bg-gray-500': openTab !== 3, 'text-white bg-[#94C34D]': openTab === 3}">
                  BCA
                </a>
                </li>
              </ul>
              
                </div>
                <div class="p-4 text-white bg-[#97989A] rounded-b-xl">
                
        <div class="relative flex flex-col w-full">
        <div class="px-4 py-1 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
              <ul class="space-y-4 text-left">
                    <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                      <a
                        href="/programs/online-bcom-accounting-and-finance"
                        class="flex items-center text-white"
                      >B.Com with Specialization in Accounting and Finance</a>
                    </li>
                    <li class="border-b-2"></li>
                    <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                      <a href="/programs/online-bcom-international-finance-and-accounting" class="flex items-center text-white">
                        B.Com with Specialization in International Finance
                        and Accounting (Accredited by ACCA, UK)
                      </a>
                    </li>

               
                  </ul>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <ul class="space-y-4 text-left">
                
                <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                  <a href="/programs/online-bba-general-management" class="flex items-center text-white">
                    BBA with Specialization in General Management
                  </a>
                </li>
                <li class="border-b-2"></li>
                <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                  <a href="/programs/online-bba-logistics-and-supply-chain-management" class="flex items-center text-white">
                    BBA with Specialization in Logistics and Supply Chain Management
                  </a>
                </li>
                <!-- <li class="border-b-2"></li>
                <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                  <a href="/programs/online-bba-marketing" class="flex items-center text-white">
                    BBA with Specialization in Marketing
                  </a> 
                </li> -->
              
              </ul>
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <ul class="space-y-4 text-left">
                                   <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                      <a href="/programs/online-bca-computer-science-and-it" class="flex items-center text-white">
                        BCA with Specialization in Computer Science and IT
                      </a>
                    </li>
                    <li class="border-b-2"></li>
                    <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                      <a href="/programs/online-bca-cloud-computing-and-cyber-security" class="flex items-center text-white">
                        BCA with Specialization in Cloud Computing & Cyber Security 
                      </a>
                    </li>
                    <li class="border-b-2"></li>
                    <li class="hover:bg-[#6C6D6F] hover:p-5 hover:rounded-md">
                      <a href="/programs/online-bca-animation-and-graphic-design" class="flex items-center text-white">
                        BCA with Specialization in Animation and Graphic Design 
                      </a>
                    </li>
                  </ul>
            </div>
          </div>
        </div>
      </div>

                </div>
              </div>
            </div>
          </div>
          <div class="w-[1/2] hidden lg:flex sm:hidden items-center ml-80 pl-12 gap-16">
            <div>
              <!-- <p class="text-lg font-semibold text-black p-4 rounded-xl">Enquire Now</p> -->
              <div @click="is_widget = true" class="hidden lg:block">
                <button class="block py-2 pl-3 pr-4 font-bold rounded lg:p-0">Enquire Now</button>
              </div>
              
            </div>
            <div>
              <a href="https://apply.onlineyenepoya.com/" target="_blank"
                class="text-lg font-semibold text-white bg-[#81C8DC] p-2 pr-8 pl-8 rounded-xl"
              >Apply Now</a>
            </div>
          </div>
          <!-- Mobile menu button -->
          <div @click="this.showMenu = !this.showMenu" class="flex md:hidden items-center">
            <button
              type="button"
              class="text-black-100 hover:text-black-400 focus:outline-none focus:text-black-400"
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="this.showMenu ? 'flex' : 'hidden'"
          class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
        >
          <li class="text-black-400 hover:text-indigo-400 text-center">
            <!-- <p class="text-lg font-semibold text-black p-4 rounded-xl">Enquire Now</p>  -->
            <div @click="is_widget = true" class="lg:hidden">
              <button class="block py-2 pl-3 pr-4 rounded lg:p-0">Enquire Now</button>
            </div>
          </li>
          <li class="text-black-400 lg:hidden hover:text-indigo-400">
            <a href="https://apply.onlineyenepoya.com/" target="_blank"
              class="text-lg font-semibold text-white bg-[#81C8DC] p-2 pr-8 pl-8 rounded-xl"
            >Apply Now</a>
          </li>
        </ul>
      </nav>
      <div v-if="is_widget" class>
        <popupView @closeWidget="is_widget = false" :widget_prop_id="this.enquire_widget" />
      </div>
    </div>
  </section>
</template>

<script>
import popupView from "../components/popupView.vue";
export default {
  components: { popupView },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      openTab: 1,
    };
  },
  // created(){
  //   setTimeout(()=>{
  //     this.is_widget = true
  //   },2000)
  // },

 
  // methods: {
  //   activeTabOne() {
  //     this.tab = 1;
  //   },
  //   activeTabTwo() {
  //     this.tab = 2;
  //   },
  //   activeTabThree() {
  //     this.tab = 3;
  //   },
  // },

  name: "pink-tabs",

  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
};
</script>

<style>

.scroll-text {
  color: #001c50;
  font-size: 15px;
  font-weight: bolder;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 480px) {
  .scroll-text {
    color: #001c50;
    font-size: small;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
  }
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 30s linear infinite;
}

.scroll-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

</style>